<template>
  开发中
</template>

<script>
export default {
  name: "List",
};
</script>

<style scoped></style>
